<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getContracts() {
      this.table.body = null

      this.table.loading = true
      this.table.errored = false
      this.table.empty = false

      api
        .get('contracts')
        .then(response => {
          if (response.data.status == 'success') {
            this.table.body = response.data.list
          } else {
            this.table.body = null
          }
        })
        .catch(error => {
          this.table.errored = error
        })
        .finally(() => {
          this.table.loading = false
          if (this.table.body == '' || this.table.body == null) {
            this.table.empty = true
          }
        })
    },
  },
  mounted() {
    this.getContracts()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Meus Contratos</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!table.body" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <b-spinner variant="default" role="status"></b-spinner>
      </div>
    </div>

    <div v-else-if="table.body && table.body.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th>#</th>
                <th>Contrato</th>
                <th>Status</th>
                <th>Data</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td>{{ td.id.split('-')[0] }}</td>
                <th>{{ td.title }}</th>
                <th>
                  <span v-if="td.status === 'pending'" class="badge badge-soft-danger font-size-12">PENDENTE</span>
                  <span v-else-if="td.status === 'signed'" class="badge badge-soft-success font-size-12">ASSINADO</span>
                </th>
                <th>{{ td.date }}</th>
                <td class="text-right">
                  <router-link :to="'/contracts/' + td.id" class="btn btn-default btn-sm btn-rounded" target="_blank">
                    {{ t('Ver') }}
                    <i class="mdi mdi-arrow-right ml-1"></i>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>